import { Project } from '../types'
import { ModalForm, FormErrors, DefaultInput } from 'apptimizm-ui'

const edit = (item: Project, toggle: (e: Event) => void, submit: () => Promise<void>, errors: FormErrors) => {
  const onToggle = () => {
    const event = new Event('click')
    toggle(event)
  }
  return (
    <div>
      <ModalForm cancel={() => onToggle()} confirm={() => {}} header="Редактирование"
        slots={{
          default: () => {
            return (
              <div>
                <label class="mt-4">
                  <DefaultInput
                    class={item.name ? 'input-placeholder-up' : ''}
                    placeholder="Название проекта" modelValue={item.name}
                    onValueChange={(v: string) => { item.name = v }}
                    errors={errors.forField('title')}
                  />
                </label>
                <label class="mt-4">
                  <DefaultInput
                    class={item.address ? 'input-placeholder-up' : ''}
                    placeholder="Адрес" modelValue={ item.address}
                    onValueChange={(v: string) => { item.address = v }}
                    errors={errors.forField('address')}
                  />
                </label>
              </div>
            )
          },
          buttons: () => <button onClick={submit} class="primary-button mt-4">Сохранить</button>
        }}
      />
    </div>
  )
}
export default edit