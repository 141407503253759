
import { Ref } from '@vue/runtime-core'
import { DefaultTable } from 'apptimizm-ui'
import axios from '@/axios'
import { Project, ProjectMeta } from './types'
import { useRouter } from 'vue-router'
import moment from 'moment'
import { TableHeader, DefaultTableExposed } from 'apptimizm-ui/src/ui/default-table/default-table'
import { ButtonLoader } from '../../UI/loader'

interface TableFilter {
  search: Ref<string>
  readonly tableParams: {
    [code: string]: string
  }
}

interface ITableProps {
  table: Ref<DefaultTableExposed>
  tableFilter: TableFilter
  editProject: (e: Event, item: Project) => void
  deleteProject: (e: Event, id: string) => void
  downloadProject: (e: Event, item: Project) => void
  isLoading: Ref<{[ code: string ]: boolean}>
}

export default ({ table, tableFilter, editProject, deleteProject, downloadProject, isLoading }: ITableProps) => {
  const router = useRouter()
  const projectMeta: ProjectMeta = new ProjectMeta()

  const toProjectPage = (id: string): void => {
    router.push({ name: 'project-id', params: { id } })
  }

  const headers: TableHeader[] = [
    { name: 'Название', sort: 'name', minWidth: '400px' },
    { name: 'Дата инвентаризации', sort: 'created_at', minWidth: '300px' },
    { name: 'Адрес', sort: 'address', minWidth: '564px' }
  ]

  const line = (item: Project) => {
    const { name, address, createdAt } = item
    return (
      <tr onClick={() => toProjectPage(item.id)}>
        <td>{name}</td>
        <td>{moment(createdAt).locale('ru').format('D MMMM YYYY')}</td>
        <td>{address}</td>
        <div class="table-actions-row">
          <button onClick={(e: Event) => editProject(e, item)} class="button-edit-project">
            <img src={require('../../assets/image/edit-icon.svg')}/>
          </button>
          <button onClick={(e: Event) => downloadProject(e, item)} class="button-load-project">
            {isLoading.value[item.id]
              ? <ButtonLoader hidden={!isLoading.value[item.id]}/>
              : <img src={require('../../assets/image/load-project.svg')}/>
            }
          </button>
          <button onClick={(e: Event) => deleteProject(e, item.id)} class="button-delete-project">
            <img src={require('../../assets/image/delete-project.svg')}/>
          </button>
        </div>
      </tr>
    )
  }

  return (
    <div class='table-wrapper'>
      <DefaultTable
        axios={axios}
        endpoint={projectMeta.endpoint}
        ref={table}
        line={line}
        defaultFilter={tableFilter.tableParams}
        itemConverter={(v: Project) => projectMeta.load(v)}
        headers={headers}
        responseItemsKey="results"
        responseTotalKey="count"
        paginationType="page"
        perPage={20}
      />
    </div>
  )
}
